.home {
    text-align: center;
}

.home-logo {
    height: 30vmin;
    width: 30vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .home-logo {
        animation: home-logo-spin infinite 20s linear;
    }
}

.home-header {
    background-color: #0b5cffd7;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.home-link {
    color: #f6fa0ec9;
}

@keyframes home-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.result{
    padding: 50px;
}
